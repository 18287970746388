.error-banner-container {
    position: fixed;
    left: 0;
    right: 0;
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.error-banner {
    border-radius: 0.25rem;
    padding: 0.5rem 0.75rem;
    background-color: var(--red);
}

.close {
    opacity: 0.5;
    cursor: pointer;

    &:hover {
        opacity: 0.75;
    }
}