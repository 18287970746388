@use "sass:color";
$wordle-grey: rgb(77, 83, 97);
$wordle-yellow: rgb(177, 115, 0);
$wordle-green: rgb(20, 126, 82);

:root {
    --bg-primary: rgb(44, 45, 53);
    --bg-secondary: rgb(38, 38, 46);
    --bg-tertiary: rgb(30, 28, 34);
    --text: rgb(223, 221, 217);
    --text-detail: #8e9297;

    --border-light: #{$wordle-grey};
    
    --wordle-grey: #{$wordle-grey};
    --wordle-grey-dark: #{color.scale($wordle-grey, $lightness: -25%)};
    --wordle-grey-light: #{color.scale($wordle-grey, $lightness: 15%)};
    --wordle-yellow: #{$wordle-yellow};
    --wordle-yellow-dark: #{color.scale($wordle-yellow, $lightness: -25%)};
    --wordle-green: #{$wordle-green};
    --wordle-green-dark: #{color.scale($wordle-green, $lightness: -25%)};

    --tooltip: #111;


    --blue: hsl(235, 85.6%, 64.7%);
    --blue-secondary: hsl(235, 51.4%, 52.4%);
    --blue-tertiary: hsl(235, 46.7%, 44.1%);
    --red: hsl(359, 82.6%, 59.4%);
    --red-secondary: hsl(359, 56.7%, 48%);
    --red-tertiary: hsl(359, 56.3%, 40.4%);
    --mention-bg: hsla(235, 85.6%, 64.7%, 0.3);
    --mention-fg: hsl(236, 83.3%, 92.9%);

    --blue-opensea: #2081e2;
}