.error {
    padding: 1em;
    color: var(--red);
    font-family: monospace;
    font-size: 20px;
}

.not-found {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 30px;
}

.not-found-header {
    font-size: 80px;
    font-weight: bold;
}
