.user-page {
    padding: 1em 4em 1em 1em;
    margin: 1rem 2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.user-header {
    font-size: 40px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.loading {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar {
    display: inline-flex;
    margin-right: 0.25em;
}

.discriminator {
    opacity: 0.625;
}

.balance {
    margin-left: 0.25em;
    display: flex;
}

.htnfts {
    flex: 1 1 auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    gap: 0.5rem;
    border-radius: 0.25rem;
	border: 1px solid var(--border-light);
}

.page-button {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    background-color: var(--blue-opensea);
    color: white;
    cursor: pointer;
    border: none;

    &:disabled {
        background-color: var(--wordle-grey-dark);
        cursor: not-allowed;
    }
}

.col0 {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    gap: 1em;
}