.navbar {
    display: flex;
    background-color: var(--bg-tertiary);
}

.main-link {
    font-size: 200%;
}

.logo {
    margin-right: 0.25em;
}

.nav-link {
    text-decoration: none;
    padding: 1rem 1.5rem;
    color: var(--text);
    display: flex;
    align-items: center;

    &:hover {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.user {
    display: flex;
    align-items: stretch;
    font-size: 125%;
}

.user-inner {
    display: flex;
    color: var(--text);
    &:hover {
        background-color: rgba(0, 0, 0, 0.5);
    }
    height: 100%;

    padding: 0 1.5rem;
    gap: 0.5em;
    align-items: center;
}

.user, .login {
    margin-left: auto;
}

.discriminator {
    opacity: 0.625;
}

.diamonds {
    display: flex;
    align-items: center;
}

.diamond-icon {
    margin-right: 0.25em;
}
