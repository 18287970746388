$arrow-size: 4px;
$margin: 0.5em;

.tooltip {
	position: absolute;

	background-color: var(--tooltip);
	color: white;
	border-radius: 0.25em;
	padding: 0.25em;

	width: max-content;

	&::before {
		content: "";
		position: absolute;
	}

	&.right {
		top: 0;
		left: 100%;
		margin-left: $margin;

		&::before {
			border-right: $arrow-size solid var(--tooltip);
			height: 0;
			left: -$arrow-size;
			top: 0%;
			border-top: $arrow-size solid transparent;
			border-bottom: $arrow-size solid transparent;
			margin-top: $arrow-size;
		}
	}

	&.left {
		top: 0;
		right: 100%;
		margin-right: $margin;

		&::before {
			border-left: $arrow-size solid var(--tooltip);
			height: 0;
			right: -$arrow-size;
			top: 0%;
			border-top: $arrow-size solid transparent;
			border-bottom: $arrow-size solid transparent;
			margin-top: $arrow-size;
		}
	}

	&.top {
		bottom: 100%;
		right: 50%;
		transform: translateX(50%);
		margin-bottom: $margin;

		&::before {
			border-top: $arrow-size solid var(--tooltip);
			width: 0;
			height: 0;
			bottom: -$arrow-size;
			right: 50%;
			transform: translateX(50%);
			border-left: $arrow-size solid transparent;
			border-right: $arrow-size solid transparent;
		}
	}

	&.bottom {
		top: 100%;
		right: 50%;
		transform: translateX(50%);
		margin-top: $margin;

		&::before {
			border-bottom: $arrow-size solid var(--tooltip);
			width: 0;
			height: 0;
			top: -$arrow-size;
			right: 50%;
			transform: translateX(50%);
			border-left: $arrow-size solid transparent;
			border-right: $arrow-size solid transparent;
		}
	}
}
