.loader {
    width: 64px;
    height: 64px;
    display: inline-block;
    border-radius: 50%;
    border: 4px solid;
    border-color: var(--text) transparent;
    box-sizing: border-box;

    animation: spin 1.2s linear infinite;
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }