.nft-preview {
    cursor: pointer;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.00);
    display: flex;

    &:hover {
        box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.5);
    }

    &:last-child {
        margin-bottom: 0;
    }

    & > * {
        width: 100%;
    }

    transition: box-shadow 0.25s ease;
}

.msg-wrapper {
    border-radius: 0.25rem;
    border: 1px solid var(--border-light);
    background-color: var(--bg-primary);
    overflow: auto;
    display: flex;
    align-items: center;
}

.price {
    margin-left: auto;
    margin-right: 1em;
}
