.recent-transactions {
    padding: 1em;
    margin: 0 4rem;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
}

.feed {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow: auto;
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid var(--border-light);
}

.transaction {
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid var(--border-light);
    width: 100%;
    box-sizing: border-box;
}

.avatar {
    border-radius: 50%;
    vertical-align: middle;
    display: inline-flex;
}
