@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&display=swap');

body {
  margin: 0;
  font-family: Poppins, sans-serif;
  color: var(--text);
  background-color: var(--bg-primary);
}

html, body {
  width: 100%;
  height: 100%;
}

:global(#root) {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main {
  overflow: auto;
  display: flex;
  flex: 1 1 auto;
}

h1 {
    font-weight: 600;
    font-size: 35px;
    margin-bottom: 5px;
}

h2 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 5px;
}

a {
    color: var(--blue-opensea);
    text-decoration: none;
}
