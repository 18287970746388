@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap');

.message-page {
    padding: 1em;
    margin: 0 4rem;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: auto;
    gap: 20px;
}

.loading {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
}

.owner {
    font-weight: 300;
    font-size: 17px;
}

.message {
    border-radius: 0.25rem;
    border: 1px solid var(--border-light);
    overflow: auto;
}

/* .sale-wrapper {
    border: 1px solid var(--border-light);
    padding: 0.75em;
    width: 100%;
    border-radius: 0.25rem;
} */

.sale-price {
    font-weight: 600;
    font-size: 30px;
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
}

.nftbutton-wrapper {
    display: flex;
    flex-direction: row;
    gap: 6px;
}

.diamond-icon {
    margin-right: 0.25em;
}

.modal-contents {
    padding: 1rem;
    font-size: 30px;
    text-align: center;
}

.modal-row {
    margin-bottom: 1rem;
    
    &:last-child {
        margin-bottom: 0;
    }
}

.modal-buttons > * {
    margin-right: 0.5em;

    &:last-child {
        margin-right: 0;
    }
}
