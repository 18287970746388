.ui {
    font-family: inherit;
    font-size: inherit;
    border-radius: 10px;
    padding: 8px;
}

.nftbutton {
    font-weight: 600;
    font-size: 20px;
    width: 240px;
    transition: text-shadow 0.25s;
    cursor: pointer;

    &:disabled {
        background-color: var(--wordle-grey-dark);
        cursor: not-allowed;

        &:hover {
            text-shadow: none;
        }
    }
}

.nbw {
    color: var(--blue-opensea);
    border: 3px solid var(--blue-opensea);
}

.nbb {
    background-color: var(--blue-opensea);
    color: #FFF;
    border: 3px solid #FFFFFF00;
}

.nbw:hover {
    text-shadow: var(--blue-opensea) 0px 0px 5px;
}

.nbb:hover {
    text-shadow: #FFF 0px 0px 5px;
}

input[type=number].ui, select.ui {
    padding: 10px;
    border-radius: 10px;
    font-size: 20px;
    border: 3px solid var(--blue-opensea);
    color: var(--bg-primary);

    &:focus {
        outline: none;
        box-shadow: var(--blue-opensea) 0px 0px 5px;
    }
}

input[type=number].ui {
    width: 90px;
}
