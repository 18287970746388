
.diamonds {
    font-weight: 600;
    font-size: 30px;
    color: white;
    vertical-align: middle;
    display: inline-flex;
    align-items: center;

    &.inline {
        font-weight: inherit;
        font-size: inherit;
        color: inherit;
    }
}

.diamond-icon {
    margin-right: 0.25em;
}